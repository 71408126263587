* {
  box-sizing: border-box;
/*   border: 1px solid red;  */

}




@font-face {
  font-family: 'customFont1'; /* Choose a name for your font */
  src: url('./assets/Shojumaru-Regular.ttf') format('truetype'); /* Provide the correct file path */

}

body {

  margin: 0px;
  padding: 0px;
  font-family: system-ui, sans-serif;
  overflow: hidden;
}

.homeSection {
  margin: 0px;
  padding: 0px;  
  height: 100vh;
  position: relative;
  
  z-index: 0;
  overflow: visible;
}



.outerSection {
  display: grid;
  grid-template-rows: 1fr 2fr 2fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 
  'navSection'
  'logoSection'
  'logoSection2'
  'inputSection'

  ;


}

.animationSection {
  
  position: absolute;
  bottom: 0px;
  justify-self: end;
  align-self: end;
  z-index: -1;
}

.gokuGif {
  position: relative;
  width: 600px;

}

.guyGif {

  border: 1px solid blue;
  right: 300px;
  width: 800px;

}

.navSection {
  display: flex;
  flex-direction: row;
  grid-area: navSection;
}

.topColumn1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 50%;

}


.topColumn2 {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;

}


.musicBlock {
  display: flex;
  border-radius: 50%;
  position: relative;
  width: 60px;
  height: 55px;
  justify-content: center;
  border: 3px solid black;
  background-color: yellow;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;

}

.musicIcon {
  margin: 5px;

}

.musicBlock:hover {


  animation: 0.5s change-color2 1 normal forwards; 
  
}

@keyframes change-color2 {
  from {
    background-color: yellow;
  }

  to {

    width: 65px;
    height: 60px;    
    background-color: yellow;

  }
}

.musicBlock:before {
  content: '';
/*   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  background: linear-gradient(45deg, yellow, rgb(179, 179, 0), yellow,  rgb(179, 179, 0), yellow,  rgb(179, 179, 0));
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing2 20s linear infinite;


  opacity: 0;
  transition: opacity .2s ease-in-out;
  border-radius: 15px;
  
}

.musicBlock:active {
  color: #000;
  
}

.musicBlock:active:after {
  background: transparent;
  
}

.musicBlock:hover:before {
  opacity: 1;
}

.musicBlock:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 20px;
  
  
}

@keyframes glowing2 {
  0% { 
      background-position: 0 0;

  }
  50% {
      background-position: 400% 0;

     }
  100% {
       background-position: 0 0;

     }
}



.portfolioBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 300px;
  height: 70px;
  gap: 10px;
  margin-right: 10px;

}

.portfolioText {
  
  font-size: 22px;
  cursor: default;
  content: '@rsterenchak';

}

.portfolioIcon {
  
  cursor: pointer;
}

.gitIcon {
  width: 45px;
}

.logoSection {
  display: flex;
  flex-direction: column;

  align-items: center;
  object-fit: contain;
  grid-area: logoSection;
}

.logoContainer {

  min-height: 400px;
  min-width: 90vw;
  background-image: url('./assets/dragon-ball-image.png') ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

}


.logoSection2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  object-fit: contain;
  grid-area: logoSection2;
}

.logoContainer2 {
  height: 350px;
  width: 55vw;
  position: absolute;


  background-image: url('./assets/MatchingGameTitle.svg') ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.inputSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: inputSection;
}


.fightButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 195px;
  border-radius: 20px;
  background-color: yellow;
  cursor: pointer;
  border: 1.5px solid black;
  position: relative;
  font-family: 'customFont1';
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  font-size: 19px;

}

.fightButton:hover, .svg-text:hover {

  
  animation: 0.5s change-color 1 normal forwards; 
  
}

@keyframes change-color {
  from {
    background-color: yellow;
  }

  to {
    height: 65px;
    width: 205px;
    font-size: 21px;
    background-color: yellow;
    fill: rgb(0, 0, 0)

  }
}



.fightButton:before {
  content: '';
/*   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  background: linear-gradient(45deg, yellow, rgb(179, 179, 0), yellow,  rgb(179, 179, 0), yellow,  rgb(179, 179, 0));
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  animation: glowing 20s linear infinite;


  opacity: 0;
  transition: opacity .2s ease-in-out;
  border-radius: 15px;
  
}

.fightButton:active {
  color: #000;
  
}

.fightButton:active:after {
  background: transparent;
  
}

.fightButton:hover:before {
  opacity: 1;
}

.fightButton:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 20px;
  
  
}

@keyframes glowing {
  0% { 
      background-position: 0 0;

  }
  50% {
      background-position: 400% 0;

     }
  100% {
       background-position: 0 0;

     }
}




.svg-element {
  
}

.svg-text {

  fill: rgb(240, 240, 0);
  stroke: black;
  stroke-width: 0.5px;


}

.svg-text:hover {
  

}


@media (min-width:320px){

  .homeSection {
    margin: 0px;
    padding: 0px;  

    z-index: 0;
    
  }

  .outerSection {
    display: grid;
    height: 100%;
    grid-template-rows: 0.8fr 1.2fr 1fr 0.8fr;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'navSection'
    'logoSection'
    'logoSection2'
    'inputSection'
    ;

  
  }

  .navSection {
    display: flex;
    flex-direction: row;
    grid-area: navSection;
  }
  
  .logoSection {
    display: flex;

    justify-content: flex-start;
    align-items: center;
    grid-area: logoSection;
  }

  .logoContainer {
    
    min-height: 130px;
    

    background-image: url('./assets/dragon-ball-image.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

  
  }
  
  .logoSection2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    object-fit: contain;
    grid-area: logoSection2;

  }
  
  .logoContainer2 {
    height: 200px;
    width: 55vw;
    position: relative;
    top: -20vh;
    left: 5vw;
    background-image: url('./assets/MatchingGameTitle.svg') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .inputSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: inputSection;
   
  }
  

}

@media (min-width:481px) {


  .homeSection {
    margin: 0px;
    padding: 0px;  

    z-index: 0;
    
  }

  .outerSection {
    display: grid;
    height: 100%;
    grid-template-rows: 0.8fr 1.4fr 1fr 0.8fr;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'navSection'
    'logoSection'
    'logoSection2'
    'inputSection'
    ;
  

  
  }

  .navSection {
    display: flex;
    flex-direction: row;
    grid-area: navSection;

  }
  
  .logoSection {
    display: flex;

    justify-content: flex-start;
    align-items: center;
    grid-area: logoSection;

  }

  .logoContainer {
    
    min-height: 180px;
    

    background-image: url('./assets/dragon-ball-image.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

  
  }
  
  .logoSection2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    object-fit: contain;
    grid-area: logoSection2;

  }
  
  .logoContainer2 {
    min-height: 200px;
    width: 55vw;
    position: relative;
    top: -19vh;
    
    left: 2vw;
    background-image: url('./assets/MatchingGameTitle.svg') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .inputSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: inputSection;
   
  }
  



}

@media (min-width:961px) {


  .homeSection {
    margin: 0px;
    padding: 0px;  

    z-index: 0;
    
  }

  .outerSection {
    display: grid;
    height: 100%;
    grid-template-rows: 0.8fr 1.6fr 1fr 0.8fr;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'navSection'
    'logoSection'
    'logoSection2'
    'inputSection'
    ;
  
  
  }

  .navSection {
    display: flex;
    flex-direction: row;
    grid-area: navSection;

  }
  
  .logoSection {
    display: flex;

    justify-content: flex-start;
    align-items: center;
    grid-area: logoSection;

  }

  .logoContainer {
    
    min-height: 220px;
    

    background-image: url('./assets/dragon-ball-image.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  
  }
  
  .logoSection2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    object-fit: contain;
    grid-area: logoSection2;
  }
  
  .logoContainer2 {
    min-height: 200px;
    width: 55vw;
    position: relative;
    top: -17vh;
    left: 1vw;
    background-image: url('./assets/MatchingGameTitle.svg') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .inputSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: inputSection;  
  }
  



}

@media (min-width:1281px) {


  .homeSection {
    margin: 0px;
    padding: 0px;  

    z-index: 0;
    
  }

  .outerSection {
    display: grid;
    height: 100%;
    grid-template-rows: 0.8fr 1.6fr 1fr 0.8fr;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'navSection'
    'logoSection'
    'logoSection2'
    'inputSection'
    ;
  
  }

  .navSection {
    display: flex;
    flex-direction: row;
    grid-area: navSection;
  }
  
  .logoSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-area: logoSection;
  }

  .logoContainer {
    
    min-height: 320px;
    

    background-image: url('./assets/dragon-ball-image.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  
  }
  
  .logoSection2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    object-fit: contain;
    grid-area: logoSection2;
  }
  
  .logoContainer2 {
    height: 330px;
    width: 575px;
    position: relative;
    top: -17vh;
    left: 1vw;
    background-image: url('./assets/MatchingGameTitle.svg') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .inputSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: inputSection;  
  }
  



}

/** PlayPage Stylings Section */


.playSection {
  margin: 0px;
  padding: 0px;
  position: relative;
  border: 1px solid rgba(255, 0, 0, 0);
  z-index: 0;
  height: 100vh;
  
  overflow: visible;

}


.outerSection2 {
  display: grid;
  grid-template-rows: 1fr 2fr 2fr 0.8fr;
  height: 100%;
  margin: 0px;
  padding: 0px;
  gap: 10px;

}


.navSection2 {
  display: flex;
  flex-direction: row;
}


.topColumn3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 30fr;
  grid-template-areas: 
  'musicBlock2 . musicBlock3 .';
  width: 50%;

}


.musicBlock2 {
  display: flex;
  border-radius: 50%;
  position: relative;
  width: 60px;
  height: 55px;
  margin-top: 10px;
  margin-left: 10px;
  justify-content: center;
  border: 3px solid black;
  background-color: yellow;
  cursor: pointer;
  grid-area: musicBlock2;
}

.musicIcon2 {
  margin: 5px;

}

.musicBlock2:hover {


  animation: 0.5s change-color3 1 normal forwards; 
  
}

@keyframes change-color3 {
  from {
    background-color: yellow;
  }

  to {

    width: 65px;
    height: 60px;    
    background-color: yellow;

  }
}

.musicBlock2:before {
  content: '';
/*   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  background: linear-gradient(45deg, yellow, rgb(179, 179, 0), yellow,  rgb(179, 179, 0), yellow,  rgb(179, 179, 0));
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing3 20s linear infinite;


  opacity: 0;
  transition: opacity .2s ease-in-out;
  border-radius: 15px;
  
}

.musicBlock2:active {
  color: #000;
  
}

.musicBlock2:active:after {
  background: transparent;
  
}

.musicBlock2:hover:before {
  opacity: 1;
}

.musicBlock2:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 20px;
  
  
}

@keyframes glowing3 {
  0% { 
      background-position: 0 0;

  }
  50% {
      background-position: 400% 0;

     }
  100% {
       background-position: 0 0;

     }
}



.musicBlock3 {
  display: flex;
  border-radius: 50%;
  position: relative;
  width: 60px;
  height: 55px;
  margin-top: 10px;
  justify-content: center;
  border: 3px solid black;
  background-color: yellow;
  cursor: pointer;
  grid-area: musicBlock3;
}

.musicIcon3 {
  margin: 5px;

}

.musicBlock3:hover {


  animation: 0.5s change-color4 1 normal forwards; 
  
}

@keyframes change-color4 {
  from {
    background-color: yellow;
  }

  to {

    width: 65px;
    height: 60px;    
    background-color: yellow;

  }
}

.musicBlock3:before {
  content: '';
/*   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  background: linear-gradient(45deg, yellow, rgb(179, 179, 0), yellow,  rgb(179, 179, 0), yellow,  rgb(179, 179, 0));
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing4 20s linear infinite;


  opacity: 0;
  transition: opacity .2s ease-in-out;
  border-radius: 15px;
  
}

.musicBlock3:active {
  color: #000;
  
}

.musicBlock3:active:after {
  background: transparent;
  
}

.musicBlock3:hover:before {
  opacity: 1;
}

.musicBlock3:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 20px;
  
  
}

@keyframes glowing4 {
  0% { 
      background-position: 0 0;

  }
  50% {
      background-position: 400% 0;

     }
  100% {
       background-position: 0 0;

     }
}




.topColumn4 {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;

}

.portfolioBlock2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 300px;
  height: 70px;
  gap: 10px;

}

.portfolioText2 {
  
  font-size: 22px;
  cursor: default;
  content: '@rsterenchak';

}

.portfolioIcon2 {
  margin-right: 10px;
  cursor: pointer;
}

.gitIcon {
  width: 45px;
}

.logoSection3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  gap: 40px;
}

.card {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 270px;
  width: 190px;
  cursor: pointer;
  overflow: hidden;
  background-color: rgb(255, 90, 90);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

}


.logoSection4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  object-fit: contain;
  gap: 40px;
}



.cardFront {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: auto; /* Maintain aspect ratio */
  display: block;
  

}

.cardImage {
  position: relative;
  width: 150px;
  height: auto
}

.cardBack {
  height: 270px;
  width: 190px;
  border-radius: 7px;
  border: 1px solid blue;

}



.currentScoreSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.currentScoreElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 280px;
  font-family: 'customFont1';
  font-size: 26px;  
  background-color: #f84040;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.currentScoreText {
  color: white;
}

.currentScoreValue {
  color: white;
}


.highScoreSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
  'scoreSection1 scoreSection2';


}

.highScoreElement {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;  
  width: 300px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-family: 'customFont1';
  font-size: 28px;
  background-color: #f84040;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}

.scoreSection1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  grid-area: scoreSection1;


}

.scoreSection2 {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  grid-area: scoreSection2;

}


.highScoreText {
  color: white;

  
}

.highScoreValue {
  color: white;
}

.endGame {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  width: 350px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  gap: 20px;
  font-family: 'customFont1';
}

.gameOverTitle {  
  font-size: 35px;
}

.retryButton {
  display: flex;
  border-radius: 17px;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 160px;
  height: 55px;
  margin: 2px;
  justify-content: center;
  border: 3px solid black;
  background-color: yellow;
  cursor: pointer;
  grid-area: musicBlock3;
  
}


.retryButton:hover {


  animation: 0.5s change-color5 1 normal forwards; 
  
}

@keyframes change-color5 {
  from {
    background-color: yellow;
  }

  to {
    font-size: 28px;
    width: 170px;
    height: 65px;  
    background-color: yellow;

  }
}

.retryButton:before {
  content: '';
/*   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  background: linear-gradient(45deg, yellow, rgb(179, 179, 0), yellow,  rgb(179, 179, 0), yellow,  rgb(179, 179, 0));
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing5 20s linear infinite;


  opacity: 0;
  transition: opacity .2s ease-in-out;
  border-radius: 15px;
  
}

.retryButton:active {
  color: #000;
  
}

.retryButton:active:after {
  background: transparent;
  
}

.retryButton:hover:before {
  opacity: 1;
}

.retryButton:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 20px;
  
  
}

@keyframes glowing5 {
  0% { 
      background-position: 0 0;

  }
  50% {
      background-position: 400% 0;

     }
  100% {
       background-position: 0 0;

     }
}




/** Mobile HomePage Warping */


/* smartphones, iPhone, portrait 480x320 phones */ 
@media (min-width:320px)  {
  .outerSection2 {
    display: grid;
    grid-template-rows: 1fr 2fr 2fr 1fr;
    height: 100%;
    margin: 0px;
    padding: 0px;
    gap: 10px;
  }
  .musicBlock3 {

    width: 50px;
    height: 45px;
    margin-top: 10px;

  }

  @keyframes change-color4 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 53px;
      height: 48px;    
      background-color: yellow;
  
    }
  }
  .musicBlock2 {

    width: 50px;
    height: 45px;
    margin-top: 10px;

  }

  @keyframes change-color3 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 53px;
      height: 48px;    
      background-color: yellow;
  
    }
  }

  .gitIcon {
    width: 30px;
    height: 30px;

  }  

  .logoSection3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 270px;


  }
  
  .card {
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 105px;
    width: 55px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
  }

  .cardBack {
    height: 125px;
    width: 75px;
    border-radius: 7px;
    border: 1px solid blue;
  
  }

  .logoSection4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 270px;
    

  }
  .highScoreElement {
    height: 40px;  
    width: 170px;
    font-size: 16px;
  }

  .currentScoreElement {
    height: 65px;
    width: 160px;
    font-size: 16px;
  }
  

}

@media (min-width:481px)  {
  .outerSection2 {
    display: grid;
    grid-template-rows: 1fr 1.4fr 1.4fr 1fr;
    height: 100%;
    margin: 0px;
    padding: 0px;
    gap: 10px;
  }
  .musicBlock3 {

    width: 50px;
    height: 45px;
    margin-top: 10px;

  }

  @keyframes change-color4 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 53px;
      height: 48px;    
      background-color: yellow;
  
    }
  }
  

  .musicBlock2 {

    width: 50px;
    height: 45px;
    margin-top: 10px;

  }

  @keyframes change-color3 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 53px;
      height: 48px;    
      background-color: yellow;
  
    }
  }

  .logoSection3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 410px;


  }
  
  .card {
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 125px;
    width: 75px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
  }

  .cardBack {
    height: 125px;
    width: 75px;
    border-radius: 7px;
    border: 1px solid blue;
  
  }

  .logoSection4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 410px;
    

  }
  .highScoreElement {
    height: 40px;  
    width: 170px;
    font-size: 16px;
  }

  .currentScoreElement {
    height: 65px;
    width: 160px;
    font-size: 16px;
  }
  

}

@media (min-width:641px)  {
   /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
   .outerSection2 {
    display: grid;
    grid-template-rows: 1fr 1.4fr 1.4fr 1fr;
    height: 100%;
    margin: 0px;
    padding: 0px;
    gap: 10px;
  }
   .musicBlock3 {

    width: 60px;
    height: 55px;
    margin-top: 10px;

  }

  .musicBlock2 {

    width: 60px;
    height: 55px;
    margin-top: 10px;

  }

  @keyframes change-color3 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 65px;
      height: 60px;    
      background-color: yellow;
  
    }
  }

  @keyframes change-color4 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 65px;
      height: 60px;    
      background-color: yellow;
  
    }
  }

   .logoSection3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 650px;
    

  }
  
  .card {
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 190px;
    width: 125px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
  }

  .cardBack {
    height: 190px;
    width: 125px;
    border-radius: 7px;
    border: 1px solid blue;
  
  }

  .logoSection4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 650px;
    

  }

  .highScoreElement {
    height: 60px;  
    width: 300px;
    font-size: 26px;  
  }

  .currentScoreElement {
    height: 100px;
    width: 280px;
    font-size: 26px;  

  }
  
  
  }

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {

  
  .musicBlock3 {
    display: flex;
    border-radius: 50%;
    position: relative;
    width: 60px;
    height: 55px;
    margin-top: 10px;
    justify-content: center;
    border: 3px solid black;
    background-color: yellow;
    cursor: pointer;
    grid-area: musicBlock3;
  }

  .musicBlock2 {

    width: 60px;
    height: 55px;
    margin-top: 10px;

  }

  @keyframes change-color3 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 65px;
      height: 60px;    
      background-color: yellow;
  
    }
  }

  @keyframes change-color4 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 65px;
      height: 60px;    
      background-color: yellow;
  
    }
  }

   .logoSection3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 650px;
    

  }
  
  .card {
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 190px;
    width: 125px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
  }

  .cardBack {
    height: 190px;
    width: 125px;
    border-radius: 7px;
    border: 1px solid blue;
  
  }

  .logoSection4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 650px;
    

  }


  
  }

  @media screen and (min-width:961px) and (min-height:1400px) {
    .outerSection2 {
      display: grid;
      grid-template-rows: 1fr 1.4fr 1.4fr 1fr;
      height: 100%;
      margin: 0px;
      padding: 0px;
      gap: 10px;
    }
    
    .musicBlock3 {
      display: flex;
      border-radius: 50%;
      position: relative;
      width: 60px;
      height: 55px;
      margin-top: 10px;
      justify-content: center;
      border: 3px solid black;
      background-color: yellow;
      cursor: pointer;
      grid-area: musicBlock3;
    }
  
    .musicBlock2 {
  
      width: 60px;
      height: 55px;
      margin-top: 10px;
  
    }

    @keyframes change-color3 {
      from {
        background-color: yellow;
      }
    
      to {
    
        width: 65px;
        height: 60px;    
        background-color: yellow;
    
      }
    }

    @keyframes change-color4 {
      from {
        background-color: yellow;
      }
    
      to {
    
        width: 65px;
        height: 60px;    
        background-color: yellow;
    
      }
    }
  
     .logoSection3 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      align-self: center;
      justify-self: center;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      gap: 20px;
      width: 650px;
      
  
    }
    
    .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      height: 275;
      width: 180px;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
    .cardBack {
      height: 275;
      width: 180px;
      border-radius: 7px;
      border: 1px solid blue;
    
    }
  
    .logoSection4 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-self: center;
      justify-self: center;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      gap: 20px;
      width: 650px;
      
  
    }
  
  
    
    }

/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) { 

  .musicBlock3 {
    display: flex;
    border-radius: 50%;
    position: relative;
    width: 60px;
    height: 55px;
    margin-top: 10px;
    justify-content: center;
    border: 3px solid black;
    background-color: yellow;
    cursor: pointer;
    grid-area: musicBlock3;
  }

  @keyframes change-color3 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 65px;
      height: 60px;    
      background-color: yellow;
  
    }
  }

  @keyframes change-color4 {
    from {
      background-color: yellow;
    }
  
    to {
  
      width: 65px;
      height: 60px;    
      background-color: yellow;
  
    }
  }

  .logoSection3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 650px;
    

  }
  
  .gitIcon {
    width: 40px;
    height: 40px;

  }  


  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 210px;
    width: 145px;
    border: 1px solid black;
    overflow: hidden; /* Prevent content from overflowing */
    position: relative; 
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    
  
  }


  .cardFront {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: auto; /* Maintain aspect ratio */
    display: block;
    
  
  }

  .cardImage {
    position: relative;
    width: 150px;
    height: auto

  }

  .cardBack {
    height: 210px;
    width: 145px;
    border-radius: 7px;
    border: 1px solid blue;
  
  }

  .logoSection4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 650px;
    

  }


}

/* hi-res laptops and desktops */
@media (min-width:1281px) { 
   
  .musicBlock3 {
    width: 60px;
    height: 55px;
    margin-top: 10px;

  }

  .musicBlock2 {

    width: 60px;
    height: 55px;
    margin-top: 10px;

  }

  .logoSection3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 850px;
    

  }
  
  .card {
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 270px;
    width: 195px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
  }

  .cardBack {
    height: 270px;
    width: 195px;
    border-radius: 7px;
    border: 1px solid blue;
  
  }

  .logoSection4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 20px;
    width: 850px;
    

  }
}



/* 

@media screen and (max-height: 1100px) {

  body {
    background-size: 2000px;

  }

  .outerSection2 {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 8fr;
    height: 100%;
    margin: 1rem;
  
  
  }



  .inputSection2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  

  }


}
 */
